import React from "react"
import { Block } from "baseui/block"

import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import Container from "../components/UI/Container"

export default () => {
  return (
    <Layout isSimple>
      <SEOPage title="Privacy Policy" description="Cylynx - privacy policy" />
      <Block marginTop="200px" />
      <Container>
        <h1>Privacy Policy</h1>
        <div>Last modified on September 2021</div> <br />
        <div>Welcome to Cylynx's Privacy Policy (“Privacy Policy). </div>
        <br />
        <div>
          Protecting your Personal Data and complying with the GDPR and other
          relevant data protection legislations is something we take very
          seriously. This Privacy Policy is intended to give you an overview of
          how we ensure this protection, what kind of data we collect, why, and
          how we deal with it.
        </div>
        <br />
        <div>
          “Personal Data” shall means in accordance with the GDPR any
          information relating to an identified or identifiable individual. This
          is broader than just information of a personal or private nature and
          includes any information that you voluntarily submit to us and that
          identifies you personally, including contact information, such as your
          name, date of birth, email address, company name, address, phone
          number, and other information about yourself or your business.
          Personal Information can also include information about you that is
          available on the internet, such as from Facebook, LinkedIn, Twitter
          and Google, or publicly available information that we acquire from
          service providers, navigational information where such information can
          directly or indirectly identify an individual. Navigational
          information refers to information about your computer and your visits
          to the websites such as your IP address, geographical location,
          browser type, referral source, length of visit and pages viewed, etc.
        </div>
        <h2>Who is responsible? </h2>
        <div>
          Responsibility for data processing and in connection with this Privacy
          Policy is with Cryptolynx Pte. Ltd. (“Cylynx”, “we”, “us”).
        </div>
        <br />
        <h2>Why do we collect, process and use data? </h2>
        <div>
          We use our website to present general information about our company
          and what we do and offer services in connection with our product
          offerings ("Service"). There are some areas of our websites or
          individual pages which serve more specific purposes. There is the
          applicants' area of our website, where potential applicants can apply
          for jobs, internships and other positions; We also offer subscriptions
          to newsletters, surveys, offers or comparable marketing and
          information services; if you subscribe to such services, we will send
          you the respective material by email; And there are other pages on
          specific topics or with specific functions. Details can be found in
          the contents section of each page. We may collect, process and use
          Personal Data for the reasons above, unless there are more specific
          reasons, which we will give when collecting that data (e.g. for
          applicants). We do not use data other than for its intended purpose in
          each case.
        </div>
        <h2> How do we obtain your Personal Data? </h2>
        <div>
          <b>Personal Data collected automatically</b>
        </div>
        <br/>
        <div>
          When you view content provided by us, we automatically collect
          information about your computer hardware and software. This
          information can include your IP address, browser type, domain names,
          internet service provider (ISP), the files viewed on our site (e.g.,
          HTML pages, graphics, etc.), operating system, clickstream data,
          access times and referring website addresses.
        </div>
        <br />
        <div>
          Cylynx cannot attribute this data to any specific person as a general
          rule (unless you log in). We do not combine this data with data from
          any other sources.
        </div>{" "}
        <br />
        <div>
          This information is required: to provide general statistics regarding
          use of the websites; to properly deliver the contents of our websites;
          to keep our websites optimized at all times; to ensure the continued
          functioning of our information technology systems and the technology
          of our websites; and to provide law enforcement authorities with the
          necessary information for prosecution in the event of a cyberattack.
        </div>
        <br />
        <div>
          For these purposes, we do link this automatically collected data to
          personal information such as name, email address, address, and phone
          number. This data is statistically analyzed by Cylynx in order to
          increase data protection and data security in our company, so we can
          ensure the best possible level of protection for the Personal Data we
          process.
        </div>
        <br />
        <div>
          <b>Personal Data obtained from you directly</b>
        </div>
        <br />
        <div>
          We record and process information you enter on our websites or send us
          otherwise. This includes data you enter in forms or contact fields
          (e.g. for the subscription to newsletters or to access certain
          content) or select from lists or menus. There are some points in our
          websites where you can send us information by uploading documents via
          those websites to our servers. That is what happens when you apply
          online for a job opening, for example. We also record and process
          Personal Data when you email someone at Cylynx. This concerns the
          contents of your emails as such, but also data obtained from queries
          to our email server, such as sender and addressee IDs, time stamp,
          errors or grounds for refusal if mail does not get through. When you
          provide your Personal Data, we will ask you to give your consent for
          processing that data.
        </div>
        <br />
        <div>
          <b>Registration information</b>
        </div>
        <br />
        <div>
          We collect personal and/or business information that you provide when
          you register for an account at the Site. This information may include
          your name, email address, GitHub username and company. We use this
          information to administer your account, provide you with the relevant
          services and information, communicate with you regarding your account,
          the Site and for customer support purposes.
        </div>
        <br />
        <div>
          <b>Information collected through the Use of the Service</b>
        </div>
        <br />
        <div>
          After registration, you may create, upload or transmit files,
          documents, videos, images, data or information as part of your use of
          the Service (collectively, "User Content"). User Content and any
          information contained in the User Content, including personal
          information you may have included, is stored and collected as part of
          the Service. You have full control of the information included in the
          User Content.
        </div>
        <br />
        <div>
          <b>Payment information</b>
        </div>
        <br />
        <div>
          If you make a purchase or payment on the Site, such as for a
          subscription, we collect transactional information provided in
          connection with your purchase or payment. Please note that we use
          third party payment processors, including Stripe, to process payments
          made to us. As such, we do not retain any personally identifiable
          financial information such as credit card numbers. Rather, all such
          information is provided directly by you to our third-party processor.
          The payment processor’s use of your personal information is governed
          by their privacy notice. To view Stripe’s privacy notice, please
          visit: https://stripe.com/privacy.
        </div>
        <br />
        <div>
          <b>Newsletters/marketing and information services</b>
        </div>
        <br />
        <div>
          {" "}
          There may be some points on Cylynx's websites where we offer
          newsletters or comparable marketing and information services. If you
          would like to use these, we will need a working email address for you
          and details to enable us to verify that this email address is really
          yours, or that the person whose address it is agrees to receive the
          newsletter. The Personal Data collected when registering for the
          newsletter will be used exclusively for the following purposes:
        </div>
        <br />
        <div>
          Sending the newsletter Consulting, marketing and advertising Designing
          the newsletter according to your needs Composing the topics of the
          newsletter according to your interests Furthermore, subscribers to the
          newsletter may be informed by email if necessary, for offering the
          newsletter service or for registration, for example in the event of
          changes to the offer of the newsletter or changes in technical
          conditions.
        </div>
        <h2> To whom we may disclose your Personal Data? </h2>
        <div>
          <b>Where does data go once it reaches Cylynx?</b>
        </div>{" "}
        <br />
        <div>
          Once you send data, or it is collected on our websites, we transmit it
          within Cylynx to the recipients that need to know it. Job
          applications, for instance, go to our human resources department and
          the department for which the position is advertised.
        </div>
        <br />
        <div>
          <b>External service providers</b>
        </div>
        <br />
        <div>
          We may share your personal data with external service providers who
          work on our behalf, such as marketing agencies, e-commerce fulfilment
          partners, market research companies, software, website hosting and
          other IT service providers. These external providers are required not
          to use your Personal Data other than to provide the services requested
          by us or otherwise in accordance with our instructions.
        </div>
        <br />
        <div>
          We use single sign-on ("SSO") such as Google to allow a user to
          authenticate their account using one set of login information. We will
          have access to certain information from those third parties in
          accordance with the authorization procedures determined by those third
          parties, including, for example, your name, username, email address,
          language preference, and profile picture. We use this information to
          operate, maintain, and provide to you the features and functionality
          of the Service.
        </div>
        <br />
        <div>
          User Content for our Service is stored and
          managed by Supabase. This includes all files, documents, videos,
          images, data or information created or stored as part of your use of
          the Service. To view Supabase's privacy policy, please visit:
          https://supabase.io/docs/company/privacy
        </div>
        <br />
        <div>
          <b>Sending data to third parties</b>
        </div>
        <br />
        <div>
          As a fundamental rule, we do not disclose, transfer, sell or otherwise
          market Personal Data to third parties, such as other companies or
          organizations without your express consent, or as required to meet our
          contractual obligations between Cylynx and you, the website user,
          which make it necessary to transfer such data.
        </div>
        <br />
        <div>
          <b>Transfer of data to countries outside the EU/EEA</b>
        </div>
        <br />
        <div>
          Where there is a sufficient legal basis, your Personal Data may be
          transferred to and processed outside the EU/EEA in other countries
          where laws and provisions governing the processing of Personal Data
          may be less stringent. In such cases, we will ensure that there are
          adequate safeguards in place to protect your Personal Data (i.e. Data
          Protection Agreement and/or EU standard data protection clauses
          adopted by the EU commission).
        </div>
        <br />
        <h2> Cookies and links to other websites</h2>
        <div>
          <b>How we use cookies in general</b>
        </div>
        <br />
        <div>
          {" "}
          A cookie is a small file which asks permission to be placed on your
          computer’s hard drive. Once you agree, the file is added, and the
          cookie helps analyze web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </div>
        <br />
        <div>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyze data about web page traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.{" "}
        </div>
        <br />
        <div>
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us. You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </div>
        <br />
        <div>
          <b>How we use cookies for advertising purposes</b>
        </div>
        <br />
        <div>
          Cookies and other ad technology such as beacons, pixels, and tags help
          us market more effectively to users that we and our partners believe
          may be interested in Cylynx. They also help provide us with aggregated
          auditing, research, and reporting, and know when content has been
          shown to you.
        </div>
        <br />
        <div>
          <b>Google Analytics </b>
        </div>
        <br />
        <div>
          This website also uses Google Analytics, a web analysis service
          provided by Google Inc. (“Google”). Google Analytics uses “cookies” to
          help the website analyze how users use the site and optimize our
          websites. As a rule, the information generated by the cookie about
          your use of this website will be transmitted to a Google server in the
          USA and stored there. For more information on terms of use and data
          protection, please visit www.google.com/analytics/terms/gb.html.
        </div>
        <br />
        <div>
          <b>Links to other websites</b>
        </div>{" "}
        <br />
        <div>
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website. Therefore, we
          cannot be responsible for the protection and privacy of any
          information which you provide whilst visiting such sites and such
          sites are not governed by this Privacy Policy. You should exercise
          caution and look at the privacy statement applicable to the website in
          question.
        </div>
        <h2> What are your rights?</h2>
        <div>You have the following data protection rights:</div>
        <br />
        <div>right not to provide or withdraw consent at any time;</div>
        <div>right to access the personal data you provided to us;</div>
        <div>right to erase personal data that we hold about you;</div>
        <div>right to restrict the processing of your personal data;</div>
        <div>right to opt out of marketing communications;</div>
        <div>right to correct any of your personal data.</div>
        <div>
          right to complain to a data protection authority about the collection
          and use of your Personal Data.
        </div>
        <br />
        <div>
          You may have additional privacy rights available to you under
          applicable laws. Please refer to the prevailing data protection laws
          which is relevant to you.
        </div>
        <h2>Security</h2>
        <div>
          We are committed to ensure that your information is secure. In order
          to prevent unauthorized access or disclosure, we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </div>
        <h2>How to contact us?</h2>
        <div>
          We welcome your feedback. If you have any comments, complaints or
          questions regarding this Privacy Policy or our processing of your
          Personal Data, or you would like to exercise any of your rights you
          can contact us:
        </div>
        <br />
        <div>Email: hello@cylynx.io</div>
        <br />
        <div>The contact details of our data protection officer are:</div>
        <br />
        <div> Cryptolynx Pte. Ltd. Blk 75 Ayer Rajah Crescent, #03-06</div>
        <div>139953, Singapore</div>
        <div>Email: dpo@cylynx.io</div>
        <h2>Updates to this Privacy Policy</h2>
        <div>
          This Privacy Policy was last updated in September 2021. We may
          occasionally update or amend it from time to time. When we make
          changes to this Privacy Policy we will update the revision data as
          identified by the date at the top of this Privacy Policy. The new
          updated or amended Privacy Policy will apply from the revision date.
          Please always verify whether you have consulted the latest version of
          our Privacy Policy.
        </div>
      </Container>
    </Layout>
  )
}
